<template>
  <div class="full">
    <div v-if="flag">
      <div class="desktop-web-container">
        <p>很抱歉，当前链接有误。请检查链接后重试</p>
      </div>
    </div>
    <div v-else>
      <div v-if="this.isMObile && this.isWeixin">
        <!-- 小程序转码 
        <div class="pageindex">
        <p>本站将于3月20日停止服务。请注意避免物料损耗。</p>
      </div>-->
        <div class="wechat-web-container">
          <p id="nameed">点击以下按钮打开小程序</p>
          <img id="imgurl" class="weapp-logo" src="http://cdn.z-hang.cn/weixin.png" />
          <div v-html="html" class="wechat-web-containerweapp"></div>
        </div>
      </div>
      <div v-else-if="this.isDesktop && this.isWeixin">
        <!-- 引导手机打开 
        <div class="pageindex">
        <p>本站将于3月20日停止服务。请注意避免物料损耗。</p>
      </div>-->
        <div class="desktop-web-container">
          <p>请在手机微信打开</p>
          <!-- <button class="btn" ref="copyButton" @click="openwx" style="top: 45%;position: absolute;">打开微信</button> -->



        </div>
      </div>
      <div v-else>
        <div class="desktop-web-container">
          <p>很抱歉，当前页面无法打开，请在手机微信打开使用。</p>
          <div v-if="this.isMObile && !this.isWeixin" class="desktop-web-container">
          <button class="btn" ref="copyButton" @click="openwx" style="top: 65%;position: absolute;">打开微信</button>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import sha1 from "crypto-js/sha1";
import { v4 } from "uuid";
import { appconfig } from "@/shared/const";
import { ref, vModelText } from "vue";
import Clipboard from "clipboard";
import { ElMessage } from 'element-plus'

export default {
  data() {
    return {
      html: '<wx-open-launch-weapp id="launch-btn" username="" appid="" path="" extra-data=""><template><style>.btn { width: 200px; height: 45px; text-align: center; font-size: 17px; display: block; margin: 0 auto; padding: 8px 24px; border: none; border-radius: 4px; background-color: #07c160; color: #fff;}</style><button class="btn">打开小程序</button></template></wx-open-launch-weapp>',
      timestamp: Math.floor(new Date().getTime() / 1000),
      UUID: v4(),
      flag: false,
      dialogVisible: false,
      innerVisible: false,
    };
  },
  mounted() {
   

    this.userpath();
    baidu();
    welcome();
    async function baidu() {
      var _hmt = _hmt || [];
      (function () {
        var hm = document.createElement("script");
        hm.async = true;
        hm.src = "https://hm.baidu.com/hm.js?537adad5b47b3b31fa9100ecc8ab9cf4";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
      })();
    }
    function welcome() {
      console.log(
        "╔=================================================================╗"
      );
      console.log(
        "║==========" +
          "\t\t\t" +
          "欢迎使用本转码工具" +
          "\t\t\t\t\t" +
          "==========║"
      );
      console.log(
        "║==========" +
          "\t\t\t" +
          "微信机器人wxrobot_zhang" +
          "\t\t\t\t" +
          "==========║"
      );
      console.log(
        "║==========" +
          "\t\t\t" +
          "使用同款网站欢迎咨询" +
          "\t\t\t\t\t" +
          "==========║"
      );
      console.log(
        "║==========" +
          "\t\t\t" +
          "拒绝偷盗源码" +
          "\t\t\t\t\t\t" +
          "==========║"
      );
      console.log(
        "║==========" +
          "\t" +
          "本站采用vue mysql mybatis redis springboot技术栈" +
          "\t" +
          "==║"
      );
      console.log(
        "╚=================================================================╝"
      );
    }
    // const button = document.querySelector('#copyButton');
  // const clipboard = new Clipboard(button);
    const clipboard = new Clipboard(this.$refs.copyButton);

    clipboard.on("success", (event) => {
      
      // console.log("Text copied:", event.text);
      ElMessage({
        message: "复制成功，请在微信粘贴URL打开",
        type: "success",
      });
      setTimeout(() => {
        window.location.href = "weixin://";
      }, 800);
      event.clearSelection();
    });
  },
  computed: {
    isMObile() {
      const ua = navigator.userAgent;
      return !!ua.match(/(iPhone|iPod|Android|ios)/i);
    },
    isDesktop() {
      // alert(navigator.userAgent);
      // console.log(navigator.userAgent);
      return !this.isMObile;
    },
    isWeixin() {
      // console.log(navigator.userAgent);
      const ua = navigator.userAgent;
      return !ua.match(/wxwork/i) && ua.match(/MicroMessenger/i);
    },
  },
  methods: {

    openwx() {
      this.$refs.copyButton.setAttribute(
        "data-clipboard-text",
        window.location.href
      );
    },
    async userpath() {
      let search = location.search;
      // console.log(search);
      if (!search) {
        this.flag = true;
        return;
      }
      let param = search.split("?")[1];
      if (!param) {
        this.flag = true;
        return;
      }
      // console.log(param);
      let res = await fetch(`https://path.itb0.com/api/search?id=${param}`);
      if (!res) {
        // 跳转路由
        this.flag = true;
        return;
      }
      let json = await res.json();
      // console.log(json);
      if (!json) {
        this.flag = true;
        return;
      }
      //问题1 返回值判断问题
      let path = json.data.path;
      // let username = json.data.appid;
      let appid = json.data.appid;
      let id = json.data.shareId;
      let name = json.data.name;
      let imgurl = json.data.imgurl;
      let extra_data = json.data.extraData;
      if (this.isWeixin && this.isMObile) {
        // if (username.includes("gh_")) {
        //   document
        //     .getElementById("launch-btn")
        //     .setAttribute("username", username);
        // } else {
        //   document.getElementById("launch-btn").setAttribute("appid", appid);
        // }
        document.getElementById("launch-btn").setAttribute("appid", appid);
        document
          .getElementById("launch-btn")
          .setAttribute("extra-data", extra_data);
        document.getElementById("nameed").innerHTML = name;
        document.getElementById("nameed").style.fontWeight = "bolder";
        document.getElementById("imgurl").src = imgurl;
        document.getElementById("launch-btn").setAttribute("path", path);
      }
      this.access_token();
    },

    async access_token() {
      let jsapi_ticket;
      axios.get("https://path.itb0.com/api/getTicket").then((res) => {
        if (!res.data == "") {
          jsapi_ticket = res.data;
          let signature0 =
            "jsapi_ticket=" +
            jsapi_ticket +
            "&noncestr=" +
            this.UUID +
            "&timestamp=" +
            this.timestamp +
            "&url=" +
            location.href;
          let signature = "" + sha1(signature0);
          this.main(signature);
        }
      });
    },
    main(signature) {
      wx.config({
        // debug: true, // 调试时可开启
        appId: appconfig.appId,
        timestamp: this.timestamp,
        nonceStr: this.UUID,
        signature: signature,
        jsApiList: ["wx-open-subscribe", "wx-open-launch-app"],
        openTagList: ["wx-open-launch-weapp", "wx-open-launch-app"], // 填入打开小程序的开放标签名
      });
    },
  },
  components: { vModelText },
};
</script>
<style scoped>
.full {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(234, 234, 234);
}
.wechat-web-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wechat-web-container p {
  position: absolute;
  top: 40%;
}
.wechat-web-container img {
  position: absolute;
  top: 20%;
  height: 64px;
  width: 64px;
}
.wechat-web-containerweapp {
  position: absolute;
  bottom: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  right: 0;
}

.desktop-web-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desktop-web-container p {
  position: absolute;
  top: 40%;
}

.ad_1,
.ad_1 button {
  font-size: 16px;
  margin-bottom: 4px;
  margin-top: 4px;
  display: inline-block;
  background-color: #22c68d;
  color: #fff;
  padding: 0 5px;
  border: 2px solid transparent;
  border-radius: 5px;
  cursor: pointer;
}
.ad_button {
  position: absolute;
  top: 50%;
  display: inline-block;
  background-color: #22c68d;
  color: #fff;
  padding: 0 5px;
  border: 2px solid transparent;
  border-radius: 5px;
  margin-top: 4px;
  font-size: 16px;
}

.footer {
  position: absolute;
  bottom: 10px;
}

.footer a {
  font-size: 12px;
}
.btn {
  width: 200px;
  height: 45px;
  text-align: center;
  font-size: 17px;
  display: block;
  margin: 0 auto;
  padding: 8px 24px;
  border: none;
  border-radius: 4px;
  background-color: #07c160;
  color: #fff;
}
.weapp-logo {
    border-radius: 9999px;
    width: 64px;
    height: 64px;
}
.pageindex{
  margin: 0 auto;
  /* text-align: center; */
  align-items: center;
  display: flex;
  flex-direction: column;
  background: #ffcc77;
}
.pageindex p{
  color: brown;
  font-weight: bold;
  font-size: 16px;
}
</style>
